
function translatePDC( pdc: Array<any>, sourceLang: string = "en", targetLang: string = "es") {
    //FilterTranslatedString 
    let geoFluencyList: any = [];
    console.log(`GEO_FLUENCY_PDC sourceLang : ${sourceLang}, targetLang : ${targetLang},pdc length : ${pdc.length},` )
    if (targetLang !== sourceLang) {
        geoFluencyList = pdc.map((item: any) => {
            if (item?.translatedString && item.translatedString[targetLang]) {
                const title = item.translatedString[targetLang].topic || item.title
                const message = item.translatedString[targetLang].text || item.message
                return {...item, title, message}
            }
            return item;
        });
    }
    if (geoFluencyList.length) {
        console.log(`GEO_FLUENCY_PDC translatedGeofluencyList length : ${geoFluencyList.length}` )
        return geoFluencyList
    }

    console.log(`GEO_FLUENCY_PDC : Translation didn't happened` )
    return pdc
}


export { translatePDC }

