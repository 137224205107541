import { ActionButton } from '@adobe/react-spectrum';
import { Refresh } from '../Theme/Icons/Icons';
import React from 'react';
import styles from '../Theme/Styles/styles.module.css';
import localization from '../Language/language';

interface IProps {
  theme: string;
  pdcCallback: any;
  language: string;
}

const PredefinedContentHeader = (props: IProps) => {
    const { theme, language = 'en' } = props;
    console.log(theme);

    const localizeStrings = localization[language]

    const pdcWidgetHeader = theme
        ? styles['pdc-widget-header-dark']
        : styles['pdc-widget-header-light'];

    const pdcWidgetHeaderContent = theme
        ? styles['pdc-widget-header-content-dark']
        : styles['pdc-widget-header-content-light'];

    const pdcWidgetHeaderIcon = theme
        ? styles['pdc-widget-header-icon-dark']
        : styles['pdc-widget-header-icon-light'];
    return (
        <div className={pdcWidgetHeader}>
            <div className={pdcWidgetHeaderContent}>
                <div className={pdcWidgetHeaderIcon}>
                    PDC
                </div>
                <span>{localizeStrings['predefined_content']}</span>
            </div>
            <ActionButton
                UNSAFE_className={styles['pdc-widget-header-refresh-button']}
                isQuiet
                onPress={() => props.pdcCallback()}
            >
                <Refresh />
            </ActionButton>
        </div>
    )
}

export default PredefinedContentHeader
