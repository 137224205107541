import React, { memo } from 'react';
import Highlighter from 'react-highlight-words';
import CustomDialogContainer from '../CustomPDCDialog';

const PDCHighlighter = ({
    category,
    searchPdc,
    list,
    customPdcLength,
    apiCallbackHandler,
    localizeStrings
}) => {
    const displayCustomComponent = (category) => {
        if (category?.includes('custom')) {
            return <CustomDialogContainer
                list={list}
                customPdcLength={customPdcLength}
                apiCallbackHandler={apiCallbackHandler}
                localizeStrings={localizeStrings}
            />
        }
        return null;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchPdc]}
                autoEscape
                textToHighlight={category}
            />
            {displayCustomComponent(category)}
        </div>
    )
}

const MemoizedHighlighter = memo(PDCHighlighter)
export default MemoizedHighlighter