import React from 'react'
function ThumbsUp() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
      <defs></defs>
      <title>Yes</title>
      <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
      <path
        className='fill'
        fill='#464646'
        d='M14.922,6.004l-3.275.005a10.73706,10.73706,0,0,0,.4-3.04A1.90165,1.90165,0,0,0,10.4605,1,1.6635,1.6635,0,0,0,8.82,2.582C8.5845,4.8595,6.1935,6.7135,4.936,7.2685A.495.495,0,0,0,4.5,7h-3a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V15h7.222a2.318,2.318,0,0,0,2.063-1.2115L16.277,8.15a1.5,1.5,0,0,0-1.355-2.146Zm.4525,1.712L13.366,13.394a.921.921,0,0,1-.871.616L5,14V8.3c1.4335-.5765,4.575-2.7625,4.82-5.7a.687.687,0,0,1,.6405-.6c.305,0,.5605.371.5865.969A7.52457,7.52457,0,0,1,10.174,7h4.75a.5.5,0,0,1,.4505.716Z'
      />
    </svg>
  )
}

export default ThumbsUp
