const initialState: any = {
  darkMode: false,
  language: 'en',
  env: {},
  toggleNotification: false,
  notificationMessage: '',
};

type actionType = {
  type: string;
  value: boolean;
  payload: any;
};

const uiReducer = (
  state: any = initialState,
  action: actionType = { type: '', value: false, payload: '' },
) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return {
        ...state,
        language: action.payload,
      };
    }
    case 'TOGGLE_DARK_MODE': {
      return {
        ...state,
        darkMode: action.payload,
      };
    }
    case 'SET_ENV_VARIABLES': {
      return {
        ...state,
        env: action.payload,
      };
    }
    case 'CLEAR_UI_DATA': {
      return initialState;
    }
    case 'TOGGLE_NOTIFICATION': {
      return {
        ...state,
        toggleNotification: action.value,
        notificationMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
