import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { loader, customerOffer } from '../Store/actions/customerInfoActions';
import { insertEligibleOffers } from '../services/analytics';
import languages from '../services/languages';
import { getConversationStatus } from '../services/utils';
import countries from '../services/countries';

const useCustomerOfferFetch = (contextData: any, isCIP: boolean, CIP_DOMAIN: string, reload: any, agentSiteLocation: string) => {
  const { jcAuthData, ocAppData, ocPlatformData } = contextData;
  const { closedPhoneCall, closedConversation } = ocAppData;

  const { callInfo: { assignedAgent = undefined, multipleAgentsConnected = undefined } = {} } = ocPlatformData;
  const interactionClosed = getConversationStatus(closedPhoneCall, closedConversation, multipleAgentsConnected, assignedAgent);
  console.log('Interaction Closed?', interactionClosed);
  const countryWithCode = jcAuthData.country ? jcAuthData.country : jcAuthData.countryCode ? jcAuthData.countryCode : jcAuthData.surfaceIpRegion;
  const country = countries[countryWithCode];
  const { modifiedCustomer: { rengaId: userId = '', contactId = '' } = {} } = ocAppData || {};
  const dispatch = useDispatch();
  const CIPData: any = useSelector((state: any) => state.customerInfo.CIP_data);
  const { currentContactId }: any = useSelector((state: any) => state.customerInfo);
  const [
    { 'Renga ID': [ciprengaId = ''] = [''], 'Active Subscription Plan': activeSubscription = [''] } = {},
    {
      'Bot Intent': [botIntent = ''] = [''],
      'Queue Path': queuePath = [''],
      'CustomerOne Sub-Issue': [subissueType = ''] = [''],
      'Number of Transfers': [transferCount = ''] = [''],
    } = {},
  ] = CIPData;
  const NoTransferCount: number = Number(transferCount);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();

    if (isCIP && isMounted && contactId && userId) {
      const params = {
        userId: userId,
        JcUserId: jcAuthData?.customerId ?? '',
        conversationId: currentContactId || contactId,
        currentContactId: ocPlatformData?.chatInfo?.currentContactId || '',
        queueName: queuePath.at(-1) ?? '',
        JcEntitlementList: jcAuthData?.entitlementsList?.split(';')?.join(',') || '',
        transferCount: NoTransferCount,
        botIntent: botIntent,
        subissueType: subissueType,
      };
      const fetchData = async (url: any, params: any) => {
        // dispatch(loader(true))
        dispatch(customerOffer(isCIP, [{ features: ['---'], meta: [], entitlement: ['---'], cctPro: '---' }]));
        console.log('CIP Customer Offer before', params);
        try {
          const { data: { json: { customerOfferList: customerOfferList = {}, entitlement = [''], cctPro = false } = {} } = {} }: any = await axios.get(
            `${url}/api/v1/customer/offer`,
            {
              params: params,
              headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
              cancelToken: source.token,
            },
          );
          if (isMounted && customerOfferList.releases[1]?.features && CIPData[0]) {
            const offers = customerOfferList?.releases?.[1];
            const deepFirstOffers = [{ features: offers?.features.length ? offers?.features : [], meta: offers.meta, entitlement: entitlement?.[0] ? entitlement : [], cctPro }];
            dispatch(customerOffer(isCIP, deepFirstOffers));
            if (cctPro) {
              offers?.features?.push('CCT-Pro');
            }

            const requestBody = {
              conversation_id: currentContactId || contactId,
              agent_email: jcAuthData?.agentEmail,
              customer_id: CIPData[0]['Renga ID'][0],
              customer_type: jcAuthData?.customerType,
              queue_name: jcAuthData?.queueName,
              queue_path: [jcAuthData?.queuePath],
              offers_eligible: offers?.features,
              origin: CIPData[1]?.Origin,
              conversation_start_date: new Date(),
              agent_site_location: agentSiteLocation,
              region: country || jcAuthData.surfaceIpRegion,
              language: languages[`${jcAuthData.language}_${jcAuthData.region}`] || 'English',
              agent_ldap: ocPlatformData?.agentInfo?.agentLDAP,
            };

            if (!interactionClosed) insertEligibleOffers(CIP_DOMAIN, requestBody);
          } else if (entitlement?.[0]) {
            const offers = customerOfferList?.releases?.[1];
            if (cctPro) {
              offers?.features?.push('CCT-Pro');
            }
            const deepFirstOffers = [{ features: [], meta: [], entitlement: entitlement, cctPro }];
            dispatch(customerOffer(isCIP, deepFirstOffers));

            const requestBody = {
              conversation_id: currentContactId || contactId,
              agent_email: jcAuthData?.agentEmail,
              customer_id: CIPData[0]['Renga ID'][0],
              customer_type: jcAuthData?.customerType,
              queue_name: jcAuthData?.queueName,
              queue_path: [jcAuthData?.queuePath],
              offers_eligible: offers?.features,
              origin: CIPData[1]?.Origin,
              conversation_start_date: new Date(),
              agent_site_location: agentSiteLocation,
              region: country || jcAuthData.surfaceIpRegion,
              language: languages[`${jcAuthData.language}_${jcAuthData.region}`] || 'English',
              agent_ldap: ocPlatformData?.agentInfo?.agentLDAP,
            };
            if (!interactionClosed) insertEligibleOffers(CIP_DOMAIN, requestBody);
          } else if (isMounted) {
            dispatch(customerOffer(isCIP, [{ features: [], meta: [], entitlement: [], cctPro }]));
          }
        } catch (error) {
          if (isMounted) {
            dispatch(customerOffer(isCIP, [{ features: [], meta: [], entitlement: [], cctPro: false }]));
            console.log('CIP cx offer err', error);
          }
        } finally {
          setTimeout(() => {
            // stop CIP analytics
            (window as any).enable_CIP = false;
          }, 100);
        }
      };
      fetchData(CIP_DOMAIN, params);
    } else if (isCIP && isMounted) {
      dispatch(customerOffer(isCIP, [{ features: [], meta: [], entitlement: [], cctPro: false }]));
    }

    return () => {
      isMounted = false;
      source.cancel();
    };
  }, [userId, reload]);
};

export default useCustomerOfferFetch;
