import axios from 'axios'

export const insertEligibleOffers = (url: string,requestBody: any) => {
  const source = axios.CancelToken.source();
  try {
    axios.post(`${url}/api/v1/analytics/offer/insert`, requestBody, {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
    })
  } catch (e) {
    console.error('Error while inserting analytics data - value add offer: ', e)
  }
}

export const updateOfferPitch = (url: string, requestBody: any) => {
  const source = axios.CancelToken.source();
  try {
    axios.put(`${url}/api/v1/analytics/offer/agent-pitch`, requestBody, {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
    })
  } catch (e) {
    console.error('Error while updating analytics - agent pitched in offer: ', e)
  }
}

export const updateOfferResponseStatus = (url: string, requestBody: any) => {
  const source = axios.CancelToken.source();
  try {
    axios.put(`${url}/api/v1/analytics/offer/customer-response`, requestBody, {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
    })
  } catch (e) {
    console.error('Error while updating analytics - update offer response: ', e)
  }
}

export const updateOfferRejectionReason = (url: string, requestBody: any) => {
  const source = axios.CancelToken.source();
  try {
    axios.put(`${url}/api/v1/analytics/offer/rejection-reason`, requestBody, {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
    })
  } catch (e) {
    console.error('Error while updating analytics - offer rejection reason : ', e)
  }
}