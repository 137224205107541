import React from 'react'
interface Props {}

interface State {
  // IS THIS THE CORRECT TYPE FOR THE state ?
  hasError: boolean
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    console.log('CIP/SCIP error: ', error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong, Please refresh and try.</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
