import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatecurrentContactId,
  setCISloader,
  updateConversationStatus,
  updatejcAuthData,
  showSearchCustomerTable,
  updatePresistChatInfo,
  customerOffer,
  cisAllowAgent,
} from '../Store/actions/customerInfoActions';
import useAttributeData from '../hooks/useAttributeData';
import useCustomerOfferFetch from '../hooks/useCustomerOfferFetch';
import useUserPersisted from '../hooks/useUserPersisted';
import useOrderQuote from '../hooks/useOrderQuote';
import Accordion from './Accordion';
import styles from '../Theme/Styles/styles.module.css';
import { Provider, defaultTheme, darkTheme, ProgressCircle, ActionButton } from '@adobe/react-spectrum';
import themes from '../Theme/Themes';
import localization from '../Language/localization';
import Refreshs from '../Theme/Icons/Refreshs';
import { SEARCH_CUSTOMER, USER_PERSISTED } from '../Store/constants/customerInfoConstants';

interface Props {
  contextData: any;
  callBackEvent: (eventType: string, eventData: any) => void;
  darkMode: boolean;
  CIP: boolean;
  language: string;
  conversationSummary: any;
  feedback: any;
  agentPath: any;
  enableSummary: boolean;
  urls: {
    HENDRIX_DOMAIN: string;
    CM_DOMAIN: string;
    CIP_DOMAIN: string;
    CCP_API_END_POINT: string;
  };
}

const CustomerInfoPanel = (prop: Props) => {
  const { CIP, urls, contextData, callBackEvent, conversationSummary, enableSummary, agentPath, feedback } = prop;
  const [reload, setReload] = useState(1);

  const {
    ocPlatformData: {
      chatInfo: { contactId = '', status = '', currentContactId = '' } = {},
      allowedAgent = false,
      callInfo: { assignedAgent = '', origin: callOrigin = '' } = {},
      agentInfo: { agentSiteLocation = '' } = {},
      transferInProgressId = '',
    } = {},
    ocAppData: { closedConversation = '', closedPhoneCall = '', originForCurrentConversation = '' },
  } = contextData;

  //API hooks
  useAttributeData(contextData, prop.darkMode, CIP, prop.language);
  useUserPersisted(CIP, urls.CIP_DOMAIN, contextData.jcAuthData, contextData.ocAppData, reload, callBackEvent);
  useCustomerOfferFetch(contextData, CIP, urls.CIP_DOMAIN, reload, agentSiteLocation);
  useOrderQuote(CIP, urls.CIP_DOMAIN, reload);

  const { CIP_data, SCIP_data, userPersisted, loading, language, presistChatInfo } = useSelector((state: any) => state.customerInfo);
  let contextualData: any = CIP ? CIP_data : SCIP_data;
  const dispatch = useDispatch();

  const { darkMode, darkModeBG, darkModeBorder, darkModeTitle, darkModeaAdobeRegular } = themes(useSelector((state: any) => state.customerInfo.darkMode));
  const refresh = () => {
    console.log('CIP/SCIP refresh');
    dispatch(setCISloader(true));
    presistChatInfo[contactId] = {
      phone: '',
      email: userPersisted?.linkedCustomer?.email ? userPersisted.linkedCustomer.email : '',
      searchCustomer: '',
    };
    dispatch(showSearchCustomerTable(false));
    dispatch(updatePresistChatInfo(presistChatInfo));
    setReload(key => key + 1); // force reload
    callBackEvent('CIP_REPHRESH', {});
  };
  //reset and clear the state
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(updatecurrentContactId(contactId));
      dispatch(updatejcAuthData(contextData?.jcAuthData));
      dispatch(customerOffer(CIP, []));
      const isSearchCustomerPersist = presistChatInfo[contactId]?.searchCustomer;
      console.log('SEARCH_CUSTOMER Persist', isSearchCustomerPersist, presistChatInfo);
      if ((isSearchCustomerPersist || contextData.jcAuthData.isMultipleAccountLinked === 'true') && !(closedConversation && closedPhoneCall)) {
        if (isSearchCustomerPersist) dispatch({ type: SEARCH_CUSTOMER, payload: presistChatInfo[contactId].searchCustomer });
        dispatch(showSearchCustomerTable(true));
      } else dispatch(showSearchCustomerTable(false));
    }
    return () => {
      dispatch({
        type: USER_PERSISTED,
        payload: [],
      });
      isMounted = false;
    };
  }, [contactId]);

  // isConversation Active
  useEffect(() => {
    const isCall = !(originForCurrentConversation === 'inbound-chat' || (originForCurrentConversation === 'chat-to-phone' && !closedConversation && closedPhoneCall));
    const isConversationActive = isCall ? assignedAgent : true;
    const isActive = !(closedConversation && closedPhoneCall) && isConversationActive;
    console.log('SCIP updateConversationStatus action', status, isConversationActive, isActive, contactId, CIP);
    dispatch(updateConversationStatus(CIP, isActive));
    if (!CIP && !isActive) dispatch(showSearchCustomerTable(false));
  }, [status, assignedAgent]);

  useEffect(() => {
    dispatch(cisAllowAgent(allowedAgent));
  }, [allowedAgent]);

  if (enableSummary) {
    contextualData = [(contextualData as Array<any>)[0], { Title: 'Current Conversation Summary' }, ...(contextualData as Array<any>).slice(1)];
  }

  return (
    <Provider theme={darkMode ? darkTheme : defaultTheme} colorScheme="light" UNSAFE_style={{ height: '100%', borderRadius: '5px' }}>
      <div className={styles['customer-info-panel'] + darkModeBG}>
        <div className={styles['cip-widget-header'] + darkModeBG + darkModeBorder + darkModeTitle}>
          <div className={styles['widget-icon']}>{CIP ? 'CI' : 'SC'}</div>
          <span>{CIP ? localization[language]['cip-title-header'] : localization[language]['scip-title-header']}</span>
          <div className={styles['cip-refresh-button'] + darkModeTitle}>
            <ActionButton UNSAFE_className={styles['cip-refresh-action-button'] + darkModeTitle} isQuiet onPress={refresh}>
              <Refreshs />
            </ActionButton>
          </div>
        </div>
        {/* <AlertMessage dark={darkMode} /> */}
        <div className={styles['cip-widget-content']}>
          {loading ? (
            <div className={styles['cip-loader']}>
              <ProgressCircle UNSAFE_className={styles['cip-module-fetch-loader']} aria-label="Loading…" isIndeterminate />
              <p className={`${styles['cip-loader-text']} ${styles['adobe-clean-regular']}` + darkModeaAdobeRegular}>
                {localization[language]['loader-fetching-customer-profile']}
              </p>
            </div>
          ) : (
            // Loop and creating diffrent Accordion session in CIP/SICP
            contextualData.map((item: any) => (
              <Accordion
                key={item.Title}
                title={item.Title}
                cipValues={item}
                isCIP={CIP}
                urls={urls}
                reload={reload}
                chatStatus={status}
                callOrigin={callOrigin}
                transferInProgressId={transferInProgressId}
                conversationSummary={conversationSummary}
                agentPath={agentPath}
                setReload={setReload}
                currentConvId={currentContactId}
                feedback={feedback}
                callBackEvent={callBackEvent}
              />
            ))
          )}
        </div>
      </div>
    </Provider>
  );
};

export default CustomerInfoPanel;
