const language = {
    'en': {
        'search_for_phrase': 'Search for a phrase...',
        'show_titles_only': 'Show titles only',
        'show_titles_only_message': 'Display titles without text preview',
        'preview_text': 'Preview text',
        'preview_text_message': 'Display titles and entire text',
        'predefined_content': 'Predefined Content',
        'maximum_favorite': 'Maximum of 8 favorited content pieces are allowed at one time',
        'maximum_custom': 'Maximum of 20 custom content pieces are allowed at one time',
        'delete_pdc_title': 'Delete Predefined Content',
        'delete_pdc_confirmation': 'Are you sure you want to delete the predefined content you created?',
        'cancel_text': 'Cancel',
        'delete_text': 'Delete',
        'add_custom_title': 'Add Custom Content',
        'add_text': 'Add',
        'category_text': 'Category',
        'content_text': 'Content',
        'empty_favorites': "No favorited messages to display",
        'empty_custom': "No custom messages to display"
    },
    'ja': {
        'search_for_phrase': 'フレーズ検索...',
        'show_titles_only': 'タイトルのみを表示',
        'show_titles_only_message': 'テキストプレビューなしでタイトルを表示',
        'preview_text': 'テキストをプレビュー',
        'preview_text_message': 'タイトルとテキスト全体を表示',
        'predefined_content': '定型文',
        'maximum_favorite': '一度に許可されるお気に入りコンテンツは 8 個までです。',
        'maximum_custom': '一度に許可されるカスタムコンテンツは最大 20 個',
        'delete_pdc_title': '定義済みコンテンツの削除',
        'delete_pdc_confirmation': '作成した定義済みコンテンツを削除してもよろしいですか?',
        'cancel_text': '[キャンセル]',
        'delete_text': '[削除]',
        'add_custom_title': 'カスタムコンテンツの追加',
        'add_text': '[追加]',
        'category_text': 'カテゴリ',
        'content_text': '[コンテンツ]',
        'empty_favorites': "表示するお気に入りメッセージはありません",
        'empty_custom': "表示するカスタムメッセージはありません"
    },
    'fr': {
        'search_for_phrase': 'Rechercher une phrase...',
        'show_titles_only': 'Afficher les titres uniquement',
        'show_titles_only_message': 'Afficher les titres sans texte d’aperçu',
        'preview_text': 'Texte d’aperçu',
        'preview_text_message': 'Afficher les titres et le texte entier',
        'predefined_content': 'Contenu prédéfini',
        'maximum_favorite': 'Un maximum de 8 éléments de contenu favoris sont autorisés à la fois',
        'maximum_custom': 'Un maximum de 20 éléments de contenu personnalisés sont autorisés à la fois',
        'delete_pdc_title': 'Supprimer le contenu prédéfini',
        'delete_pdc_confirmation': 'Êtes-vous sûr de vouloir supprimer le contenu prédéfini que vous avez créé ?',
        'cancel_text': 'Annuler',
        'delete_text': 'Supprimer',
        'add_custom_title': 'Ajouter du contenu personnalisé',
        'add_text': 'Ajouter',
        'category_text': 'Catégorie',
        'content_text': 'Contenu',
        'empty_favorites': "Aucun message favori à afficher",
        'empty_custom': "Aucun message personnalisé à afficher"
    },
    'de': {
        'search_for_phrase': 'Suche nach einer Phrase...',
        'show_titles_only': 'Nur Titel anzeigen',
        'show_titles_only_message': 'Titel ohne Textvorschau anzeigen',
        'preview_text': 'Textvorschau',
        'preview_text_message': 'Titel und gesamten Text anzeigen',
        'predefined_content': 'Vordefinierte Inhalte',
        'maximum_favorite': 'Maximal 8 Lieblingsinhalte sind gleichzeitig zulässig.',
        'maximum_custom': 'Maximal 20 benutzerdefinierte Inhalte sind gleichzeitig zulässig',
        'delete_pdc_title': 'Vordefinierten Inhalt löschen',
        'delete_pdc_confirmation': 'Möchten Sie den vordefinierten Inhalt, den Sie erstellt haben, wirklich löschen?',
        'cancel_text': 'Stornieren',
        'delete_text': 'Löschen',
        'add_custom_title': 'Benutzerdefinierten Inhalt hinzufügen',
        'add_text': 'Hinzufügen',
        'category_text': 'Kategorie',
        'content_text': 'Inhalt',
        'empty_favorites': "Keine bevorzugten Nachrichten zum Anzeigen",
        'empty_custom': "Keine benutzerdefinierten Nachrichten zum Anzeigen"
    },
    'es': {
        'search_for_phrase': 'Buscar por frase...',
        'show_titles_only': 'Mostrar solo títulos',
        'show_titles_only_message': 'Mostrar títulos sin previsualización del texto',
        'preview_text': 'Previsualización del texto',
        'preview_text_message': 'Mostrar títulos y texto completo',
        'predefined_content': 'Contenido predefinido',
        'maximum_favorite': 'Se permite un máximo de 8 piezas de contenido favorito a la vez.',
        'maximum_custom': 'Se permite un máximo de 20 piezas de contenido personalizado a la vez',
        'delete_pdc_title': 'Eliminar contenido predefinido',
        'delete_pdc_confirmation': '¿Confirma que desea eliminar el contenido predefinido que ha creado?',
        'cancel_text': 'Cancelar',
        'delete_text': 'Eliminar',
        'add_custom_title': 'Añadir contenido personalizado',
        'add_text': 'Añadir',
        'category_text': 'Categoría',
        'content_text': 'Contenido',
        'empty_favorites': "No hay mensajes favoritos para mostrar",
        'empty_custom': "No hay mensajes personalizados para mostrar"
    },
    'it': {
        'search_for_phrase': 'Cerca una frase...',
        'show_titles_only': 'Mostra solo i titoli',
        'show_titles_only_message': 'Visualizza titoli senza anteprima testo',
        'preview_text': 'Anteprima testo',
        'preview_text_message': 'Visualizza titoli e intero testo',
        'predefined_content': 'Contenuto predefinito',
        'maximum_favorite': 'Sono consentiti un massimo di 8 contenuti preferiti contemporaneamente.',
        'maximum_custom': 'Sono consentiti un massimo di 20 contenuti personalizzati contemporaneamente',
        'delete_pdc_title': 'Elimina contenuti predefiniti',
        'delete_pdc_confirmation': 'Sei sicuro di voler eliminare il contenuto predefinito che hai creato?',
        'cancel_text': 'Annulla',
        'delete_text': 'Elimina',
        'add_custom_title': 'Aggiungi contenuti personalizzati',
        'add_text': 'Inserisci',
        'category_text': 'Categoria',
        'content_text': 'Contenuto',
        'empty_favorites': "Nessun messaggio preferito da visualizzare",
        'empty_custom': "Nessun messaggio personalizzato da visualizzare"
    },
    'pt-BR': {
        'search_for_phrase': 'Pesquisar uma frase...',
        'show_titles_only': 'Mostrar somente títulos',
        'show_titles_only_message': 'Exibir títulos sem pré-visualização de texto',
        'preview_text': 'Texto de pré-visualização',
        'preview_text_message': 'Exibir títulos e texto completo',
        'predefined_content': 'Conteúdo Predefinido',
        'maximum_favorite': 'São permitidas no máximo 8 peças de conteúdo favoritas ao mesmo tempo.',
        'maximum_custom': 'Máximo de 20 peças de conteúdo personalizado são permitidas ao mesmo tempo',
        'delete_pdc_title': 'Excluir conteúdo predefinido',
        'delete_pdc_confirmation': 'Tem certeza de que deseja excluir o conteúdo predefinido que você criou?',
        'cancel_text': 'Cancelar',
        'delete_text': 'Excluir',
        'add_custom_title': 'Adicionar conteúdo personalizado',
        'add_text': 'Adicionar',
        'category_text': 'Categoria',
        'content_text': 'Conteúdo',
        'empty_favorites': "Nenhuma mensagem favorita para exibir",
        'empty_custom': "Nenhuma mensagem personalizada para exibir"
    },
    'zh-TW': {
        'search_for_phrase': '搜尋詞組...',
        'show_titles_only': '僅顯示標題',
        'show_titles_only_message': '顯示不含文字預覽的標題',
        'preview_text': '預覽文本',
        'preview_text_message': '顯示標題和全文',
        'predefined_content': '預先定義內容',
        'maximum_favorite': '一次最多允許 8 個我的最愛內容。',
        'maximum_custom': '一次最多允許 20 個自定義內容件',
        'delete_pdc_title': '刪除預定義內容',
        'delete_pdc_confirmation': '您確定要刪除您建立的預先定義內容嗎？',
        'cancel_text': '取消',
        'delete_text': '刪除',
        'add_custom_title': '添加自定義內容',
        'add_text': '新增',
        'category_text': '類別',
        'content_text': '內容',
        'empty_favorites': "沒有最愛的訊息可顯示",
        'empty_custom': "沒有可顯示的自訂訊息"
    },
    'zh-CN': {
        'search_for_phrase': '搜索短语...',
        'show_titles_only': '只显示标题',
        'show_titles_only_message': '显示标题（不提供文本预览）',
        'preview_text': '预览文本',
        'preview_text_message': '显示标题和全文本',
        'predefined_content': '预定义内容',
        'maximum_favorite': '一次最多允许 8 个收藏的内容。',
        'maximum_custom': '一次最多允许 20 个自定义内容',
        'delete_pdc_title': '删除预定义内容',
        'delete_pdc_confirmation': '您确定要删除您创建的预定义内容吗？',
        'cancel_text': '取消',
        'delete_text': '删除',
        'add_custom_title': '添加自定义内容',
        'add_text': '添加',
        'category_text': '类别',
        'content_text': '内容',
        'empty_favorites': "没有可显示收藏的消息",
        'empty_custom': "没有自定义消息可显示"
    },
    'ko': {
        'search_for_phrase': '구문 검색...',
        'show_titles_only': '제목만 표시',
        'show_titles_only_message': '텍스트 미리보기 없이 제목 표시',
        'preview_text': '텍스트 미리보기',
        'preview_text_message': '제목 및 전체 텍스트 표시',
        'predefined_content': '미리 정의된 내용',
        'maximum_favorite': '즐겨찾는 콘텐츠는 한 번에 최대 8개까지 허용됩니다.',
        'maximum_custom': '한 번에 최대 20개의 사용자 지정 콘텐츠를 허용할 수 있습니다.',
        'delete_pdc_title': '사전 정의된 콘텐츠 삭제',
        'delete_pdc_confirmation': '만든 사전 정의된 콘텐츠를 정말로 삭제하시겠습니까?',
        'cancel_text': '취소',
        'delete_text': '삭제',
        'add_custom_title': '사용자 지정 콘텐츠 추가',
        'add_text': '추가',
        'category_text': '카테고리',
        'content_text': '콘텐츠',
        'empty_favorites': "즐겨찾는 메시지가 표시되지 않음",
        'empty_custom': "표시할 사용자 지정 메시지 없음"
    }
}
export default language
