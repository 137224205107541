export const setLocalStorage = (conversationId: string, property: string, value: any) => {
  try {
    if (!conversationId) {
      return false;
    }
    const availableData = sessionStorage.getItem('CCM_LOCAL_STORAGE') || '{}';
    const existingData = JSON.parse(availableData);
    const currentConvData = existingData[conversationId] || null;
    if (currentConvData === null) {
      // NEW INSERTION
      const newData = {};
      newData[property] = value;
      existingData[conversationId] = newData;
    } else {
      // UPDATE OPERATION
      existingData[conversationId][property] = value;
    }
    const updatedData = JSON.stringify(existingData);
    sessionStorage.setItem('CCM_LOCAL_STORAGE', updatedData);
    return true;
  } catch {
    console.error(' Not able to update Local storage ');
    return false;
  }
};
export const getLocalStorage = (conversationId: string) => {
  try {
    if (!conversationId) {
      return false;
    }
    const availableData = sessionStorage.getItem('CCM_LOCAL_STORAGE') || '{}';
    const existingData = JSON.parse(availableData);
    return existingData[conversationId] || {};
  } catch {
    console.error('Not able to fetch Local storage');
    return null;
  }
};
export const clearLocalStorage = () => {
  try {
    sessionStorage.removeItem('CCM_LOCAL_STORAGE');
    return true;
  } catch {
    console.error(' Not able to clear Local storage ');
    return false;
  }
};
export const removeConvDataFromLocalStorage = (conversationId: string) => {
  try {
    const availableData = sessionStorage.getItem('CCM_LOCAL_STORAGE') || '{}';
    const existingData = JSON.parse(availableData);
    const currentConvData = existingData[conversationId] || null;
    if (currentConvData !== null) {
      delete existingData[conversationId];
      const updatedData = JSON.stringify(existingData);
      sessionStorage.setItem('CCM_LOCAL_STORAGE', updatedData);
      return true;
    }
    return true;
  } catch {
    console.error(' Not able to clear Local storage by conversation Id ');
    return false;
  }
};
