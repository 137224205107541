import React from 'react'

export const ChatHeader = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={30}
    height={30}
    viewBox='0 0 30 30'
    {...props}
  >
    <path d='M15 0A15 15 0 110 15 15 15 0 0115 0z' fill='transparent' />
    <path
      d='M21.06 8.515a.758.758 0 01.758.758v9.091a.758.758 0 01-.758.758h-7.132a.757.757 0 00-.536.222l-2.181 2.18v-1.645a.758.758 0 00-.758-.758H8.939a.758.758 0 01-.758-.758v-9.09a.758.758 0 01.758-.758zM8.939 7a2.273 2.273 0 00-2.273 2.273v9.091a2.273 2.273 0 002.273 2.273h.757v3.631a.379.379 0 00.647.267l3.9-3.9h6.818a2.273 2.273 0 002.273-2.273V9.273A2.273 2.273 0 0021.06 7z'
      fill='#FFFF'
    />
  </svg>
)

export const FirstChat = (props: any) => (
  <svg id="icon_-_first_time_customer" data-name="icon - first time customer" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.className}>
    <rect id="Rectangle_150931" data-name="Rectangle 150931" width="18" height="18" fill="rgba(255,255,255,0)" />
    <g id="noun-award-954130" transform="translate(-5.574 -5.863)">
      <g id="Group_161737" data-name="Group 161737" transform="translate(6.575 7)">
        <path id="Path_84285" data-name="Path 84285" d="M14.6,959.362a5.526,5.526,0,1,0,5.526,5.526A5.526,5.526,0,0,0,14.6,959.362Zm0,2.317.991,1.994L17.81,964l-1.6,1.549.379,2.195L14.6,966.7l-1.983,1.036L13,965.545,11.393,964l2.217-.323Zm-5.052,7.153-2.974,4.294,2.674-.535.713,2.5,2.657-4.15A5.884,5.884,0,0,1,9.549,968.832Zm10.088,0a5.884,5.884,0,0,1-3.069,2.105l2.657,4.15.713-2.5,2.674.535Z" transform="translate(-6.575 -959.362)" fill={props.fillColor} />
      </g>
    </g>
  </svg>
)

export const Phone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.579" height="16.921" viewBox="0 0 16.579 16.921">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_151009" data-name="Rectangle 151009" width="16.579" height="16.921" fill="#fff" />
      </clipPath>
    </defs>
    <g id="Group_161830" data-name="Group 161830" clipPath="url(#clip-path)">
      <path id="Path_84298" data-name="Path 84298" d="M201.442.479a.9.9,0,0,0,.152,1,1.042,1.042,0,0,0,.777.284,6.969,6.969,0,0,1,5.989,3.677,6.7,6.7,0,0,1,.872,3.3.857.857,0,0,0,.948.872.823.823,0,0,0,.834-.929,9.083,9.083,0,0,0-2.786-6.292c-.265-.208-.72-.607-1.213-.929A8.681,8.681,0,0,0,202.257,0a.733.733,0,0,0-.815.474Zm-.171,3.942a.844.844,0,0,0,.853.644,3.779,3.779,0,0,1,3.809,3.772,1.02,1.02,0,0,0,.19.569.9.9,0,0,0,1.042.265.813.813,0,0,0,.531-.834,5.565,5.565,0,0,0-5.515-5.553,1.405,1.405,0,0,0-.474.095.922.922,0,0,0-.436,1.042Z" transform="translate(-194.435 0.001)" fill="#fff" />
      <path id="Path_84299" data-name="Path 84299" d="M4.486,72.949l.855-.855c.256-.256.043-.8-.211-1.051l-2.69-2.69c-.255-.255-.347-.019-.6.237l-.855.855Zm5.1,5.1.855-.855a.724.724,0,0,1,.97.13l2.695,2.695c.253.253.419.747.163,1l-.854.855ZM5.913,76.622c-2.467-2.451-2.1-3-1.831-3.269l.091-.091L.667,69.757l-.112.112c-.473.473-1.985,3.539,3.453,8.977s8.5,3.926,8.975,3.455l.112-.112L9.271,78.365l-.094.094c-.264.264-.8.621-3.27-1.833Z" transform="translate(0 -65.934)" fill="#fff" />
    </g>
  </svg>
)
