import React from 'react'

function ThumbsDown() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
      <title>No</title>
      <rect id='Canvas' fill='#ff13dc' opacity='0' width='18' height='18' />
      <path
        className='fill'
        fill='#464646'
        d='M16.277,9.85,14.285,4.212A2.318,2.318,0,0,0,12.222,3H5a.5.5,0,0,0-.5-.5h-3A.5.5,0,0,0,1,3v8a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,5,11v-.238c1.2725.587,3.5885,2.415,3.82,4.656A1.6635,1.6635,0,0,0,10.4605,17a1.9023,1.9023,0,0,0,1.5865-1.9685,10.73831,10.73831,0,0,0-.4-3.0405l3.275.005A1.5,1.5,0,0,0,16.277,9.85ZM14.9235,11h-4.75a7.52559,7.52559,0,0,1,.873,4.0315c-.026.6-.2815.966-.5865.9685a.687.687,0,0,1-.6405-.6c-.245-2.9365-3.3865-5.1225-4.82-5.7V4l7.4955-.01a.921.921,0,0,1,.871.616l2.0085,5.678a.5.5,0,0,1-.451.716Z'
      />
    </svg>
  )
}

export default ThumbsDown
