import { useEffect, useState } from 'react';
import { createCustomPdcList, removeCustomPdcList } from '../Api';

export function useCustomPdc(customPdcListProps: any, agentId: string, pdcBaseURL: string) {
    const [customPdcList, setCustomPdcList] = useState<any>([]);
    const [currentAgentId, setCurrentAgentId] = useState<string>('')

    useEffect(() => {
        setCustomPdcList(customPdcListProps)
    }, [customPdcListProps])

    useEffect(() => {
        setCurrentAgentId(agentId)
    }, [agentId])

    const createCustomPdc = async(pdcContent: any) => {
        try {
            const updatedCustomPdcList = [...customPdcList, {...pdcContent}]
            const res = await createCustomPdcList(currentAgentId, "custom-pdc", pdcContent, pdcBaseURL)
            const formattedCustomPdcList = [...updatedCustomPdcList]
            const idx = formattedCustomPdcList.findIndex(pdc => pdc.title === pdcContent.title && pdc.message === pdcContent.message);
            if(idx > -1) formattedCustomPdcList[idx].messageId = res.data?.messageId
            setCustomPdcList(formattedCustomPdcList)
            sessionStorage.setItem(`custom-pdc-${currentAgentId}`, JSON.stringify(formattedCustomPdcList));
        }
        catch (err) {
            console.error("Error occured while creating custom pdc")
            console.error(err)
            
        }
    }

    const deleteCustomPdc = async (pdcContent: any) => {
        try {
            const updatedCustomPdcList = customPdcList.filter((pdc: any) => pdc.messageId !== pdcContent.messageId)
            setCustomPdcList(updatedCustomPdcList)
            await removeCustomPdcList(currentAgentId, "custom-pdc", pdcContent, pdcBaseURL)
            sessionStorage.setItem(`custom-pdc-${currentAgentId}`, JSON.stringify(updatedCustomPdcList));
        }
        catch (err) {
            console.error("Error occured while removing custom pdc")
            console.error(err)
        }
    }

    return { customPdcList, createCustomPdc, deleteCustomPdc }
}

