import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getcustomerInfo, loader, showfraudAlertStatus, showSearchCustomerTable } from '../Store/actions/customerInfoActions';
import { USER_PERSISTED } from '../Store/constants/customerInfoConstants';

const useUserPersisted = (isCIP: boolean, CIP_DOMAIN: string, jcAuthData: any, ocAppData: any, reload: number, callBackEvent: any) => {
  const dispatch = useDispatch();
  const SCIPData: any = useSelector((state: any) => state.customerInfo.SCIP_data);
  const { currentContactId, isConverstationActive, presistChatInfo, userPersisted: userPersist }: any = useSelector((state: any) => state.customerInfo);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();
    const setFraudAlert = (selectedCustomer: any) => {
      const isFraudAlert =
        selectedCustomer?.fraudStatus && (selectedCustomer.fraudStatus.toLowerCase() === 'accountfraudlock' || selectedCustomer.fraudStatus.toLowerCase() === 'legallock');
      if (isFraudAlert) {
        dispatch(showfraudAlertStatus({ show: isFraudAlert, message: selectedCustomer.fraudStatus }));
      } else dispatch(showfraudAlertStatus({ show: false, message: '' }));
    };
    const searchCustomer = async (email: string) => {
      const searchParams = {
        conversationId: currentContactId,
        email: email,
        phone: '',
      };
      return await axios.post(`${CIP_DOMAIN}/api/v1/customer/search`, searchParams, {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
      });
    };
    const loadCustomer = async (loadParams: any) =>
      await axios.post(`${CIP_DOMAIN}/api/v1/customer/load`, loadParams, {
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
      });
    const fetchCustomer = async (concId: string) =>
      await axios.get(`${CIP_DOMAIN}/api/v1/customer/fetch`, {
        params: { conversationId: concId },
        headers: { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' },
        cancelToken: source.token,
      });

    const handleChangeCustomer = async (selectedCustomer: any) => {
      const { linkedCustomer: selectedCustomerInfo = {}, conversationId: contactId = '' } = selectedCustomer;
      const data = { ...selectedCustomerInfo, contactId };
      callBackEvent('UPDATE_CUSTOMER_INFO', data);
      console.log('SCIP handle change customer', selectedCustomer);
    };

    const userPersisted = async () => {
      try {
        dispatch(loader(true));
        let loadParams: any = {};
        const getcustomerRes: any = await searchCustomer(jcAuthData.email);
        console.log('SCIP userPersisted search', getcustomerRes);
        // Pre-Chat Survey, cx found
        if (getcustomerRes.data?.json?.length && jcAuthData.customerType?.toLowerCase() === 'unauthenticated' && jcAuthData.email && jcAuthData.email.match(/@/g)) {
          // Selected customer based priority of customer type
          const customerInfo = getcustomerRes?.data?.json?.filter((account: { type: string }) => account.type?.toLowerCase() !== 'type2e');
          let selectedCustomer: any = customerInfo.find((acctount: { type: string }) => acctount.type?.toLowerCase() === 'type1');
          if (!selectedCustomer) {
            selectedCustomer = customerInfo[0];
          }
          selectedCustomer.firstName = jcAuthData?.firstName;
          selectedCustomer.lastName = jcAuthData?.lastName;
          loadParams = {
            conversationId: currentContactId,
            linkedCustomer: {
              lastName: selectedCustomer.lastName,
              firstName: selectedCustomer.firstName,
              email: selectedCustomer.email,
              fraudStatus: selectedCustomer.temporaryStatus ? selectedCustomer.temporaryStatus : '',
              rengaId: selectedCustomer.rengaId,
              phone: selectedCustomer.phone,
            },
          };
          const userPersistedRes: any = await loadCustomer(loadParams);
          const userPersistedResData = userPersistedRes?.data?.json;
          if (userPersistedResData) {
            dispatch({
              type: USER_PERSISTED,
              payload: userPersistedResData,
            });
            handleChangeCustomer(userPersistedResData);
            setFraudAlert(userPersistedResData.linkedCustomer);
          }
        } else {
          // Auth and cx not found chats
          let selectedCustomer: any;
          if (getcustomerRes.data?.json.length) {
            selectedCustomer = getcustomerRes?.data?.json?.find((acctount: { rengaId: string }) => acctount.rengaId?.toLowerCase() === jcAuthData.customerId?.toLowerCase());
          }
          const newPhone = jcAuthData.phone ? jcAuthData.phone : jcAuthData.phoneNumber ? jcAuthData.phoneNumber : '';
          const splitEntitlementWithColen = jcAuthData.entitlementsList.split(';');
          const splitEntitlementWithcomma = jcAuthData.entitlementsList.split(',');
          const finalEntitlement =
            jcAuthData.entitlementsList.includes(';') && splitEntitlementWithColen.length > 0 && splitEntitlementWithColen[0]
              ? splitEntitlementWithColen
              : splitEntitlementWithcomma.length > 0 && splitEntitlementWithcomma[0]
              ? jcAuthData.entitlementsList.split(',')
              : [];
          loadParams = {
            conversationId: currentContactId,
            linkedCustomer: {
              lastName: jcAuthData.lastName,
              firstName: jcAuthData.firstName,
              email: jcAuthData.email,
              fraudStatus: selectedCustomer?.temporaryStatus ? selectedCustomer.temporaryStatus : '',
              rengaId: jcAuthData.customerId,
              phone: newPhone,
            },
            entitlement: finalEntitlement,
          };

          const userPersistedRes: any = await loadCustomer(loadParams);
          const { data: { json: { linkedCustomer: userPersistedResData = {}, entitlement = '' } = {} } = {} } = userPersistedRes;

          const customerData = {
            conversationId: currentContactId,
            linkedCustomer: {
              firstName: validateString(userPersistedResData.firstName, jcAuthData.firstName),
              lastName: validateString(userPersistedResData.lastName, jcAuthData.lastName),
              email: validateString(userPersistedResData.email, jcAuthData.email),
              rengaId: validateString(userPersistedResData.rengaId, jcAuthData.customerId),
              phone: validateString(userPersistedResData.phone, newPhone),
              fraudStatus: userPersistedResData.fraudStatus || '',
            },
            entitlement: entitlement ? entitlement : '',
          };
          if (customerData) {
            dispatch({
              type: USER_PERSISTED,
              payload: customerData,
            });
            handleChangeCustomer(customerData);
            setFraudAlert(userPersistedResData.linkedCustomer);
          }
        }
        dispatch(loader(false));
      } catch (err) {
        dispatch(loader(false));
        console.log('SCIP isUser persisted err', err);
      }
    };

    const unAuthUserPersisted = async () => {
      try {
        dispatch(loader(true));
        const loadParams = {
          conversationId: currentContactId,
          linkedCustomer: {
            lastName: jcAuthData.lastName,
            firstName: jcAuthData.firstName,
            email: '',
            fraudStatus: '',
            rengaId: jcAuthData.customerId,
            phone: jcAuthData.phone ? jcAuthData.phone : jcAuthData.phoneNumber ? jcAuthData.phoneNumber : '',
          },
        };
        const userPersistedRes: any = await loadCustomer(loadParams);
        const userPersistedResData = userPersistedRes?.data?.json;
        if (userPersistedResData) {
          dispatch({
            type: USER_PERSISTED,
            payload: userPersistedResData,
          });
          handleChangeCustomer(userPersistedResData);
          setFraudAlert(userPersistedResData.linkedCustomer);
          dispatch(loader(false));
        }
      } catch (error) {
        console.log('SCIP unAuthUserPersisted error', error);
        dispatch(loader(false));
      }
    };

    const fetchUser = async (linkedId: string) => {
      try {
        dispatch(loader(true));
        const fetchUserRes: any = await fetchCustomer(linkedId ? linkedId : currentContactId);
        if (isMounted && fetchUserRes?.data?.json?.linkedCustomer) {
          const { data: { json: { linkedCustomer: fetchUserData = {}, entitlement = '' } = {} } = {} } = fetchUserRes;
          const finalPhone = fetchUserData.phone ? fetchUserData.phone : jcAuthData.phone ? jcAuthData.phone : jcAuthData.phoneNumber;
          const customerData = {
            conversationId: currentContactId,
            linkedCustomer: {
              firstName: validateString(fetchUserData.firstName, jcAuthData.firstName),
              lastName: validateString(fetchUserData.lastName, jcAuthData.lastName),
              email: validateString(fetchUserData.email, jcAuthData.email),
              rengaId: validateString(fetchUserData.rengaId, jcAuthData.customerId),
              phone: finalPhone || '',
              fraudStatus: fetchUserData.fraudStatus || '',
            },
            entitlement: entitlement ? entitlement : '',
          };
          dispatch({
            type: USER_PERSISTED,
            payload: customerData,
          });
          handleChangeCustomer(customerData);
          setFraudAlert(fetchUserRes.data.json.linkedCustomer);
        } else if (jcAuthData.email) {
          userPersisted();
        } else unAuthUserPersisted();
        dispatch(loader(false));
      } catch (error) {
        console.log('SCIP fetch customer error', error);
        dispatch(loader(false));
      }
    };

    const getBotAuthUser = async () => {
      try {
        dispatch(loader(true));
        const fetchUserRes: any = !isConverstationActive ? await fetchCustomer(currentContactId) : {};
        const fetchUserInfo: any = fetchUserRes?.data?.json?.linkedCustomer;
        console.log('SCIP getBotAuthUser fetch customer', fetchUserRes);
        if (isMounted && fetchUserInfo) {
          const fetchUserData = fetchUserRes.data.json.linkedCustomer;
          const fetchUserentitlement = fetchUserRes.data.json.entitlement || '';
          const customerData = {
            conversationId: currentContactId,
            linkedCustomer: {
              firstName: fetchUserData.firstName || '',
              lastName: fetchUserData.lastName || '',
              email: fetchUserData.email || '',
              rengaId: fetchUserData.rengaId || '',
              fraudStatus: fetchUserData.fraudStatus || '',
            },
            entitlement: fetchUserentitlement ? fetchUserentitlement : '',
          };
          dispatch({
            type: USER_PERSISTED,
            payload: customerData,
          });
          handleChangeCustomer(customerData);
          setFraudAlert(fetchUserRes.data.json.linkedCustomer);
        }

        if (isMounted && (isConverstationActive || !fetchUserInfo)) {
          const getcustomerRes: any = await searchCustomer(jcAuthData.botAuthUserInputData);
          const customerInfo = getcustomerRes.data?.json || [];
          if (customerInfo.length) {
            const customerInfoWithoutT2E = customerInfo.filter((account: { type: string }) => account.type?.toLowerCase() !== 'type2e');
            let selectedCustomer: any = customerInfoWithoutT2E.find((acctount: { type: string }) => acctount.type?.toLowerCase() === 'type1');
            if (!selectedCustomer) {
              selectedCustomer = customerInfoWithoutT2E[0];
            }
            const loadParams = {
              conversationId: currentContactId,
              linkedCustomer: {
                lastName: selectedCustomer.lastName || '',
                firstName: selectedCustomer.firstName || '',
                email: selectedCustomer.email || '',
                fraudStatus: selectedCustomer.temporaryStatus || '',
                rengaId: selectedCustomer?.rengaId || '',
              },
            };
            const userPersistedRes: any = await loadCustomer(loadParams);
            const userPersistedResData = userPersistedRes?.data?.json;
            if (userPersistedResData) {
              console.log('SCIP getBotAuthUser auth selected cx', userPersistedResData);
              dispatch({
                type: USER_PERSISTED,
                payload: userPersistedResData,
              });
              handleChangeCustomer(userPersistedResData);
              setFraudAlert(userPersistedResData.linkedCustomer);
            }
          } else {
            const loadParams = {
              conversationId: currentContactId,
              linkedCustomer: {
                lastName: jcAuthData.lastName || '',
                firstName: jcAuthData.firstName || '',
                email: jcAuthData.botAuthUserInputData,
                fraudStatus: '',
                rengaId: '',
              },
            };
            const userPersistedRes: any = await loadCustomer(loadParams);
            const userPersistedResData = userPersistedRes?.data?.json;
            if (userPersistedResData) {
              console.log('SCIP getBotAuthUser unauth', userPersistedResData);
              dispatch({
                type: USER_PERSISTED,
                payload: userPersistedResData,
              });
              handleChangeCustomer(userPersistedResData);
              setFraudAlert(userPersistedResData.linkedCustomer);
            }
          }
        }
        dispatch(loader(false));
      } catch (error) {
        dispatch(loader(false));
        console.log('SCIP getBotAuthUser error', error);
      }
    };

    if (isMounted && !isCIP && jcAuthData.botAuthUserInputData) {
      console.log('SCIP getBotAuthUser', jcAuthData.email, isConverstationActive);
      getBotAuthUser();
    } else if (isMounted && !isCIP && !isConverstationActive && jcAuthData.linkedConversationId && ocAppData?.originForCurrentConversation === 'chat-to-phone') {
      //Handle Phone leg
      console.log('SCIP phone leg', jcAuthData.linkedConversationId, isConverstationActive, ocAppData.originForCurrentConversation);
      fetchUser(jcAuthData.linkedConversationId);
    } else if (isMounted && !isCIP && isConverstationActive && CIP_DOMAIN && currentContactId && jcAuthData?.email) {
      // User Persist with load API for active Chat and auth chat
      console.log('SCIP userPersisted', jcAuthData.email, isConverstationActive);
      userPersisted();
    } else if (isMounted && !isCIP && !isConverstationActive && CIP_DOMAIN && currentContactId && CIP_DOMAIN) {
      console.log('SCIP fetch', jcAuthData.email, isConverstationActive);
      // Fetch User for inactive chat and unauth chat
      fetchUser('');
    } else if (isMounted && !isCIP && isConverstationActive && CIP_DOMAIN && currentContactId && !jcAuthData?.email) {
      // User Persist with load API for active Chat and unauth chat
      console.log('SCIP unauthUserPersisted', jcAuthData.email, isConverstationActive);
      unAuthUserPersisted();
    }

    return () => {
      isMounted = false;
      source.cancel();
    };
  }, [currentContactId, isConverstationActive, reload]);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();
    const [{ 'Renga ID': [scipRengaID = ''] = '' } = {}] = SCIPData;
    if (
      isMounted &&
      isConverstationActive &&
      jcAuthData.isMultipleAccountLinked === 'true' &&
      !scipRengaID.toLowerCase().includes('@adobeid') &&
      jcAuthData.customerId &&
      currentContactId &&
      !userPersist?.linkedCustomer?.email &&
      !presistChatInfo[currentContactId]?.searchCustomer
    ) {
      dispatch(showSearchCustomerTable(true));
      dispatch(getcustomerInfo(CIP_DOMAIN, currentContactId, '', jcAuthData.customerId, presistChatInfo));
    } else if (!presistChatInfo[currentContactId]?.searchCustomer) {
      dispatch(showSearchCustomerTable(false));
    }
    return () => {
      isMounted = false;
      source.cancel();
    };
  }, [userPersist]);
};
const validateString = (string1: string, string2: string) => (string1 ? string1 : string2);

export default useUserPersisted;
