import { useEffect, useState } from 'react';
import { createFavoritePdcList, removeFavoritePdcList } from '../Api';

export function useFavoritePdc(favPdcListProps: any, pdcQueueName: string, agentId: string) {
    const [favPdcList, setFavPdcList] = useState<any>([]);
    const [currentAgent, setCurrentAgent] = useState<string>('')

    console.log("PDC", pdcQueueName)

    useEffect(() => {
        setFavPdcList(favPdcListProps)
    }, [favPdcListProps])

    useEffect(() => {
        setCurrentAgent(agentId)
    }, [agentId])


    const createFavorites = async (pdcContent: any, pdcBaseURL: string) => {
        try {
            const updatedCustomPdcList = [...favPdcList, pdcContent]
            setFavPdcList(updatedCustomPdcList)
            await createFavoritePdcList(currentAgent, "favorite-pdc", pdcContent, pdcBaseURL)
            sessionStorage.setItem(`favorites-${currentAgent}`, JSON.stringify(updatedCustomPdcList));
        }
        catch (err) {
            console.error("Error occured whiler creating favorite pdc")
        }
    }

    const removeFavorites = async (pdcContent: any, pdcBaseURL: string) => {
        try {
            const updatedCustomPdcList = favPdcList.filter((pdc: any) => pdc.messageId !== pdcContent.messageId)
            setFavPdcList(updatedCustomPdcList)
            await removeFavoritePdcList(currentAgent, "favorite-pdc", pdcContent, pdcBaseURL)
            sessionStorage.setItem(`favorites-${currentAgent}`, JSON.stringify(updatedCustomPdcList));
        }
        catch (err) {
            console.error("Error occured")
        }
    }

    return { favPdcList, createFavorites, removeFavorites }
}

