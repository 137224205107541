import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../Theme/Styles/styles.module.css';
import localization from '../Language/localization';
import themes from '../Theme/Themes';
import { Link } from '@adobe/react-spectrum';
import ConversationSummaryFeedback from './ConversationSummaryFeedback';

type Props = {
  agentPath: any;
  feedbackGotten: any;
  callBackEvent: (eventType: string, eventData: any) => void;
  conversationSummary: {
    [key: string]: any;
  };
  currentConvId: string;
};

function ConversationSummary({ conversationSummary, agentPath, callBackEvent, feedbackGotten, currentConvId }: Props) {
  const language = useSelector((state: any) => state.customerInfo.language);
  const { darkModeFieldValues, darkModea } = themes(useSelector((state: any) => state.customerInfo.darkMode));
  const isConversationActive: boolean = useSelector((state: any) => state.customerInfo.isConverstationActive);

  return (
    <div>
      <div className={styles['accordion-content-fields']}>
        <span className={styles['accordion-content-fields-keys-cip']}>{localization[language]['Conversation with']}</span>
        <div className={styles['accordion-content-fields-values-summary'] + darkModeFieldValues}>{agentPath}</div>
      </div>
      <div className={styles['accordion-content-fields-conversation-summary']}>
        <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>{localization[language]['Main customer issue']}</div>
        <div className={styles['accordion-content-fields-values-summary'] + darkModeFieldValues}>{conversationSummary?.main_issue ?? '- No data -'}</div>
      </div>
      <div className={styles['accordion-content-fields-conversation-summary']}>
        <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>{localization[language]['Conversation Points']}</div>
        <div className={styles['accordion-conversation-summary-conversation-points']}>
          <ul>
            {conversationSummary?.conversation_points && conversationSummary?.conversation_points.length > 0
              ? conversationSummary?.conversation_points
                  ?.filter(point => point.trim().length)
                  .map((point, index) => (
                    <li className={styles['accordion-content-fields-values-summary'] + darkModeFieldValues} key={index}>
                      {point ?? '- No data -'}
                    </li>
                  ))
              : '- No data -'}
          </ul>
        </div>
      </div>
      <div className={styles['accordion-content-fields-conversation-summary-links']}>
        <div className={styles['accordion-conversation-summary-main-issue-keys-cip']}>{localization[language]['Links Provided']}</div>
        {conversationSummary?.links && conversationSummary?.links?.length > 0
          ? conversationSummary?.links?.map((link: string, index: number) => {
              // all links should be non-relative, handle case the link doesn't start with http or //
              const redirectLink = link.slice(0, 4).toLowerCase() === 'http' || link.slice(0, 2) === '//' ? link : `//${link}`;
              return (
                <Link UNSAFE_className={styles['accordion-content-fields-values-isnota-conversation-summary-links']} key={index}>
                  <a href={redirectLink} target="_blank" className={styles['accordion-content-fields-values-isnota'] + darkModea} rel="noreferrer">
                    {link}
                  </a>
                </Link>
              );
            })
          : '- No data -'}
      </div>
      {isConversationActive && <ConversationSummaryFeedback feedbackGotten={feedbackGotten} currentConvId={currentConvId} callBackEvent={callBackEvent} />}
    </div>
  );
}

export default ConversationSummary;
