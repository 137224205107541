import React from 'react';

const HeartEmpty = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.96" height="12.35" viewBox="0 0 14.96 12.35">
        <g id="noun-heart-5545998" transform="translate(-83.999 -60.41)" opacity="0.5">
            <path id="Path_84467" data-name="Path 84467" d="M97.909,61.46a3.591,3.591,0,0,0-5.072,0l-1.358,1.359-1.361-1.361a3.586,3.586,0,0,0-5.072,5.072l6.087,6.087a.482.482,0,0,0,.687,0l6.087-6.087a3.581,3.581,0,0,0,0-5.069Zm-.686,4.382-5.744,5.741-5.744-5.741a2.612,2.612,0,0,1,3.7-3.693l1.7,1.7a.488.488,0,0,0,.689,0l1.7-1.7a2.612,2.612,0,0,1,3.694,3.693Z" />
        </g>
    </svg>
)

export default HeartEmpty;