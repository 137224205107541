import React from 'react';
import styles from '../../Theme/Styles/styles.module.css';
import { connect } from 'react-redux';
import utils, { getRouteToMarketValue } from '../../services/utils';
import { setCISloader, showSearchCustomerTable } from '../../Store/actions/customerInfoActions';
import { ProgressCircle } from '@adobe/react-spectrum';

type propsData = {
  SCIPData: any;
  urls: {
    HENDRIX_DOMAIN: string;
    CM_DOMAIN: string;
    CIP_DOMAIN: string;
    CCP_API_END_POINT: string;
  };
  rengaId: any;
  showSearchCustomerTableDispatch: (payload: any) => void;
  setCISLoaderDispatch: (payload: any) => void;
  searchCustomerResponse: any;
  iFrameHeight: any;
  isCISLoading: boolean;
  language: string;
  isCISallowed: boolean;
  isConverstationActive: boolean;
  currentContactId: string;
  jcAuthData: any;
  userPersisted: any;
  origin: string;
  chatId: string;
  phoneId: string;
};
interface iState {
  iframeUrl: string;
}
interface QueryParams {
  id?: string;
  custId?: string;
  email: string;
  ui: string;
  'chat-queue-id': string;
  'chat-session-id': string;
  features: string;
  'route-to-market': string;
}
interface urls {
  HENDRIX_DOMAIN: string;
  CM_DOMAIN: string;
  CIP_DOMAIN: string;
  CCP_API_END_POINT: string;
}

class CustomerInteractionSummary extends React.Component<propsData, iState> {
  constructor(props: propsData) {
    super(props);
    this.state = {
      iframeUrl: '',
    };
  }

  componentDidMount() {
    const activeEmail = this.props.SCIPData[0]['Email Address'][0] || '';
    this.setState({ iframeUrl: '' });
    if (activeEmail) {
      this.props.setCISLoaderDispatch(true);
      this.setUrl(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { rengaId: currentRengaId, origin: currentOrigin, phoneId: currentPhoneId, chatId: currentChatId } = this.props;
    const { rengaId: newRengaId, origin: newOrigin, phoneId: newPhoneId, chatId: newChatId } = nextProps;

    if (currentRengaId !== newRengaId || currentOrigin !== newOrigin || currentPhoneId !== newPhoneId || currentChatId !== newChatId) {
      this.setUrl(nextProps);
    }
  }

  setUrl = async (params: any) => {
    const { SCIPData, urls, jcAuthData } = params;
    const isPrechat = jcAuthData?.customerType && jcAuthData.email && jcAuthData.customerType.toLowerCase() === 'unauthenticated';
    const rengaId = this.props.userPersisted?.linkedCustomer?.rengaId;
    const isPrechatFlow = isPrechat && rengaId && !(rengaId.match(/adobeid/gi) || rengaId.match(/@/gi));
    if (!this.props.isCISallowed || isPrechatFlow) {
      this.props.setCISLoaderDispatch(false);
      this.setState({ iframeUrl: '' });
      return;
    }
    const origin = SCIPData[2].Origin[0] || '';
    const routeToMarket = getRouteToMarketValue(origin);
    const rengaIdObj: any = await utils.customerIdValidationForCIS(params, urls, jcAuthData);
    const activeEmail = SCIPData[0]['Email Address'][0] || '';
    const skill = jcAuthData.currentQueue || '';
    const convId = SCIPData[3]['Phone ID'][0] ? SCIPData[3]['Phone ID'][0] : SCIPData[3]['Chat ID'][0] || '';
    const custId = rengaIdObj.rengaId;
    console.log('CIS custId + email + skill + success + validCustomer', custId, activeEmail, skill, rengaIdObj.success);
    if (custId && activeEmail && skill && rengaIdObj.success && routeToMarket && convId) {
      const isHendrix360: Boolean = JSON.parse(localStorage.getItem('fastFollowFlags')).HENDRIX_360;
      const queryParams: QueryParams = {
        email: activeEmail,
        ui: 'le-chat',
        'chat-queue-id': skill,
        'chat-session-id': convId,
        features: 'msdynamics',
        'route-to-market': routeToMarket,
      };
      queryParams[isHendrix360 ? 'id' : 'custId'] = custId;
      const url = this.constructCISUrl(queryParams, urls, isHendrix360);
      this.setState({ iframeUrl: url });
      this.props.setCISLoaderDispatch(false);
      console.log('CIS URL LOADED: ' + this.state.iframeUrl);
    } else {
      this.setState({ iframeUrl: '' });
      this.props.setCISLoaderDispatch(false);
    }
  };

  constructCISUrl = (queryParams: QueryParams, urls: urls, isHendrix360: Boolean) => {
    const queryParamsString = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    // Hendrix360 migration
    const isProd = window.location.hostname === 'oac.adobe.com';
    const hendrix360 = isHendrix360 ? urls.HENDRIX_DOMAIN : isProd ? 'https://hendrix.adobe.com' : 'https://hendrix.stage.adobe.com';

    if (isHendrix360) {
      return `${hendrix360}/app/cis?authType=okta&${queryParamsString}`;
    } else {
      return `${hendrix360}/radon_java_webapp/hx5/#/v1/customer/${encodeURIComponent(queryParams.custId)}/customer-interaction-summary?${queryParamsString}`;
    }
  };

  render() {
    const cisLoader = this.props.isCISLoading && this.props.SCIPData && this.props.SCIPData[0]['Email Address'][0];
    const showIframe = this.state.iframeUrl && this.props.SCIPData && this.props.SCIPData[0]['Email Address'][0];
    return (
      <React.Fragment>
        {cisLoader && (
          <div className={styles['cis-loader-parent']}>
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          </div>
        )}
        {showIframe && (
          <div className={`${styles['accordion-content-cis']} ${styles[this.props.isCISLoading ? 'scip-cis-hide' : '']}`}>
            <iframe
              src={this.state.iframeUrl}
              width="100%"
              height={'800px'}
              scrolling="yes"
              sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-forms allow-modals"></iframe>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  SCIPData: state.customerInfo.SCIP_data,
  rengaId: state.customerInfo.SCIP_data[0]['Renga ID'][0],
  searchCustomerResponse: state.customerInfo.searchCustomer,
  iFrameHeight: state.customerInfo.iFrameHeightCIS,
  isCISLoading: state.customerInfo.isCISLoading,
  language: state.customerInfo.language,
  isCISallowed: state.customerInfo.isCISallowed,
  isConverstationActive: state.customerInfo.isConverstationActive,
  currentContactId: state.customerInfo.currentContactId,
  jcAuthData: state.customerInfo.jcAuthData,
  userPersisted: state.customerInfo.userPersisted,
  origin: state.customerInfo.SCIP_data[2]['Origin'][0],
  chatId: state.customerInfo.SCIP_data[3]['Chat ID'][0],
  phoneId: state.customerInfo.SCIP_data[3]['Phone ID'][0],
});

const mapDispatchToProps = (dispatch: any) => ({
  showSearchCustomerTableDispatch: function (data: boolean) {
    dispatch(showSearchCustomerTable(data));
  },
  setCISLoaderDispatch: function (flag: boolean) {
    dispatch(setCISloader(flag));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInteractionSummary);
