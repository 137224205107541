import React from 'react';
import { getCategories, getCustomPdcList, getFavoritePdcList } from '../Api';

interface IState {
    favPdcList: Array<any>;
    customPdcList: Array<any>;
    categoriesList: Array<any>;
}

interface IProps {
    pdcList?: any;
    callBackEvent?: (eventType: string, eventData: object) => void;
    contextData?: any;
    pdcTheme?: string;
    favPdcList?: any;
    agentId?: string;
    pdcQueueName?: string;
    customPdcList?: any
}


function withFavoriteAndCustomPdc(WrappedComponent: React.MemoExoticComponent<any>) {
    return class extends React.Component<IProps, IState> {
        constructor(props: any) {
            super(props)
            this.state = {
                favPdcList: [],
                customPdcList: [],
                categoriesList: []
            }
        }

        componentDidMount() {
            this.fetchCustomPDC()
            this.fetchFavoritePDC()
            this.fetchCategories()
        }

        componentDidUpdate(prevProps: any) {
            const { agentId: previousAgentId } = prevProps.contextData;
            const { agentId: currentAgentId } = this.props.contextData
            if (previousAgentId !== currentAgentId || prevProps.pdcList !== this.props.pdcList) {
                this.fetchCustomPDC();
                this.fetchFavoritePDC()
            }
        }

        fetchCategories = async () => {
            const res : any = await getCategories(this.props.contextData?.pdcBaseURL);
            this.setState({ categoriesList: res?.data })
        }


        fetchFavoritePDC = async () => {
            const { agentId, agentFeatureTags, pdcBaseURL } = this.props.contextData
            if (agentFeatureTags.pdcFavorites !== 'true') {
                return;
            }
            const cachedPdcResponse: any = sessionStorage.getItem(`favorites-${agentId}`);
            if (cachedPdcResponse && cachedPdcResponse?.length) {
                this.setState({ favPdcList: JSON.parse(cachedPdcResponse) })
                return;
            }
            try {
                const res = await getFavoritePdcList(agentId, pdcBaseURL);
                const customPdcList = res?.data?.json?.Items || [];

                this.setState({ favPdcList: customPdcList?.[0]?.pdcContent || [] })
                sessionStorage.setItem(`favorites-${agentId}`, JSON.stringify(customPdcList?.[0]?.pdcContent || []));
            }
            catch (err) {
                console.error(err)
            }
        }

        fetchCustomPDC = async () => {
            const { agentId, agentFeatureTags, pdcBaseURL } = this.props.contextData
            if (agentFeatureTags.pdcFavorites !== 'true') {
                return;
            }
            const cachedPdcResponse: any = sessionStorage.getItem(`custom-pdc-${agentId}`);
            if (cachedPdcResponse && cachedPdcResponse?.length) {
                this.setState({ customPdcList: JSON.parse(cachedPdcResponse) })
                return;
            }
            try {
                const res = await getCustomPdcList(agentId, pdcBaseURL);
                const customPdcList = res?.data?.json?.Items || [];
                this.setState({ customPdcList: customPdcList?.[0]?.pdcContent || [] })
                sessionStorage.setItem(`custom-pdc-${agentId}`, JSON.stringify(customPdcList?.[0]?.pdcContent || []));
            }
            catch (err) {
                console.error(err)
            }
        }

        render() {
            const { contextData: { agentId, pdcQueueName } } = this.props;
            return <WrappedComponent
                favPdcList={this.state.favPdcList}
                agentId={agentId}
                pdcQueueName={pdcQueueName}
                customPdcList={this.state.customPdcList}
                categoriesList={this.state.categoriesList}
                {...this.props}
            />;
        }
    };
}

export default withFavoriteAndCustomPdc